<template>
    <div class="page-device-details">
        <el-tabs v-model="activeName" v-if="deviceData">
            <el-tab-pane label="基础信息" name="base">
                <el-card class="box-card">
                    <!--<div slot="header" class="card-header">
                    <span>基础信息</span>
                  </div>-->
                    <el-row>
                        <el-col :span="3">ID</el-col>
                        <el-col :span="9">{{ deviceData.id }}</el-col>
                        <el-col :span="3">状态</el-col>
                        <el-col :span="9">
                            <el-tag type="success" size="mini">{{ getDeviceStatusDesc(deviceData.status) }}</el-tag>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="3">网络状态</el-col>
                        <el-col :span="9">
                            <el-tag type="warning" size="mini">{{ deviceData.isOnline ? "在线" : "离线" }}</el-tag>
                        </el-col>
                        <el-col :span="3">订单数</el-col>
                        <el-col :span="9">{{ deviceData.orderCount }}</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="3">设备名称</el-col>
                        <el-col :span="9">{{ deviceData.type && deviceData.type.name }}</el-col>
                        <el-col :span="3">设备类型</el-col>
                        <el-col :span="9">{{ deviceData.type && deviceData.type.categoryDesc }}</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="3">激活时间</el-col>
                        <el-col :span="9">{{ deviceData.activationTime }}</el-col>
                        <el-col :span="3">总流水</el-col>
                        <el-col :span="9">{{ deviceData.orderTurnover }}</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="3">输液宝剩余数</el-col>
                        <el-col :span="9">{{ deviceData.stock || '--' }}</el-col>
                        <el-col :span="3">输液宝可还插槽</el-col>
                        <el-col :span="9">{{ deviceData.emptySlots || '--' }}</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="3">最后登陆时间</el-col>
                        <el-col :span="9">{{ deviceData.lastLoginTime || '--' }}</el-col>
                        <el-col :span="3">最后离线时间</el-col>
                        <el-col :span="9">{{ deviceData.lastOfflineTime || '--' }}</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="3">最后一次上报时间</el-col>
                        <el-col :span="9">{{ deviceData.lastReportTime || '--' }}</el-col>
                        <el-col :span="3">最后一次租借时间</el-col>
                        <el-col :span="9">{{ deviceData.lastBorrowTime || '--' }}</el-col>
                    </el-row>
                    <el-row>
                        <template v-if="deviceData.agent">
                            <el-col :span="3">代理</el-col>
                            <el-col :span="9">{{ deviceData.agent.name }}【{{ deviceData.agent.id }}】【{{
                                deviceData.agent.levelDesc
                            }}】</el-col>
                        </template>
                        <template v-if="deviceData.merchant">
                            <el-col :span="3">商家</el-col>
                            <el-col :span="9">{{ deviceData.merchant.name }}【{{ deviceData.merchant.creatorUserId }}】</el-col>
                        </template>
                    </el-row>
                    <el-row>
                        <template v-if="deviceData.businessAgent">
                            <el-col :span="3">业务员</el-col>
                            <el-col :span="9">{{ deviceData.businessAgent.name }}【{{ deviceData.businessAgent.id }}】【{{
                                deviceData.businessAgent.levelDesc
                            }}】</el-col>
                        </template>
                        <template v-if="deviceData.version">
                            <el-col :span="3">版本号</el-col>
                            <el-col :span="9">{{ deviceData.version }}</el-col>
                        </template>
                    </el-row>
                </el-card>
            </el-tab-pane>
            <el-tab-pane label="设备信息" name="device">
                <el-card class="box-card">
                    <!--<div slot="header" style="">
                      <span class="span-header">设备信息</span>
                    </div>-->
                    <div v-if="deviceData.isOnline || deviceData.deviceInfo && deviceData.deviceInfo.iccid">
                        <el-row type="flex" align="middle" :gutter="20">
                            <el-col :span="12">
                                <el-card>
                                    <span class="fwb">设备ID/名称：</span>【{{ deviceData.id }}】{{ deviceData.type && deviceData.type.name }}
                                </el-card>
                            </el-col>
                            <el-col :span="12">
                                <el-card>
                                    <span class="fwb">网络状态：</span><el-tag type="warning" size="mini">{{ deviceData.isOnline ? "在线" : "离线" }}</el-tag>
                                </el-card>
                            </el-col>
                        </el-row>
                        <el-row type="flex" align="middle" :gutter="20">
                            <el-col :span="12">
                                <el-card>
                                    <span class="fwb">SIM卡ID：</span>{{ deviceData.deviceInfo && deviceData.deviceInfo.iccid || '--' }}
                                    <div class="fr">
                                        <el-button type="primary" size="small" @click="gainVsersion(deviceData.id, 3)"
                                            :disabled="!deviceData.isOnline">{{
                                                deviceData.isOnline ? '同步ICCID' : '只能同步在线的设备'
                                            }}</el-button>
                                    </div>
                                    <div class="clearb"></div>
                                </el-card>
                            </el-col>
                            <el-col :span="12">
                                <el-card>
                                    <span class="fwb">设备版本号：</span>{{ deviceData.version }}
                                    <div class="fr">
                                        <el-button type="primary" size="small" @click="gainVsersion(deviceData.id, 4)"
                                            :disabled="!deviceData.isOnline">{{
                                                deviceData.isOnline ? '同步设备版本号' : '只能同步在线的设备'
                                            }}</el-button>
                                    </div>
                                    <div class="clearb"></div>
                                </el-card>
                            </el-col>
                            <!--<el-col :span="6">SIM卡ID：{{ deviceData.deviceInfo.iccid || '--' }}</el-col>
                      <el-col :span="6">
                        <el-button type="warning" size="small" @click="gainVsersion(deviceData.id, 3)"
                          :disabled="!deviceData.isOnline">{{ deviceData.isOnline ? '更新ICCID' : '只能更新在线的设备' }}</el-button>
                      </el-col>
                      <el-col :span="6">设备版本号：{{ deviceData.version }}</el-col>
                      <el-col :span="6">
                        <el-button type="info" size="small" @click="gainVsersion(deviceData.id, 4)"
                          :disabled="!deviceData.isOnline">{{ deviceData.isOnline ? '更新设备版本号' : '只能更新在线的设备' }}</el-button>
                      </el-col>-->
                        </el-row>
                        <el-row type="flex" align="middle" :gutter="20">
                            <el-col :span="12">
                                <el-card>
                                    <span>
                                        <span class="fwb">误码率：</span>{{ deviceData.deviceInfo.networkSER }}，
                                    </span>
                                    <span>
                                        <span class="fwb">信号强度(0~31)：</span>{{ deviceData.networkCSQ }}，
                                    </span>
                                    <span>
                                        <span class="fwb">网络格式：</span>{{ deviceData.deviceInfo.networkModelDesc }}
                                    </span>
                                    <div class="fr">
                                        <el-button type="primary" size="small" @click="gainVsersion(deviceData.id, 2)"
                                            :disabled="!deviceData.isOnline">{{
                                                deviceData.isOnline ? '同步设备网络信息' : '只能同步在线的设备'
                                            }}</el-button>
                                    </div>
                                    <div class="clearb"></div>
                                </el-card>
                            </el-col>
        
                            <el-col :span="12">
                                <el-card>
                                    <template v-if="deviceData.deviceInfo && deviceData.deviceInfo.serverAddress">
                                        <span>
                                            <span class="fwb">心跳：</span>{{ deviceData.deviceInfo.serverAddress.heartbeat }}秒，
                                        </span>
                                        <span>
                                            <span class="fwb">端口：</span>{{ deviceData.deviceInfo.serverAddress.port }}，
                                        </span>
                                        <span>
                                            <span class="fwb">服务器地址：</span>{{ deviceData.deviceInfo.serverAddress.address }}
                                        </span>
                                    </template>
                                    <span class="col9" v-else>
                                        ~暂无服务器数据，请先同步~
                                    </span>
                                    <div class="fr">
                                        <el-button type="primary" size="small" @click="gainVsersion(deviceData.id, 5)"
                                            :disabled="!deviceData.isOnline">{{
                                                deviceData.isOnline ? '同步服务器地址' : '只能同步在线的设备'
                                            }}</el-button>
                                    </div>
                                    <div class="clearb"></div>
                                </el-card>
                            </el-col>
                        </el-row>
                        <!--<el-row type="flex" align="middle">
                      <el-col :span="2">误码率：</el-col>
                      <el-col :span="2">{{ deviceData.deviceInfo.networkSER }}</el-col>
                      <el-col :span="2">信号强度(0~31)：</el-col>
                      <el-col :span="2">{{ deviceData.networkCSQ }}</el-col>
                      <el-col :span="2">网络格式：</el-col>
                      <el-col :span="3">{{ deviceData.deviceInfo.networkModelDesc }}</el-col>
                      <el-col :span="4">
                        <el-button type="danger" size="small" @click="gainVsersion(deviceData.id, 2)"
                          :disabled="!deviceData.isOnline">{{ deviceData.isOnline ? '更新设备网络信息' : '只能更新在线的设备' }}</el-button>
                      </el-col>
                    </el-row>-->
                    </div>
                    <div v-else class="mrgb20 colff0" style="font-size:30px;">设备没有被成功获取到过</div>
                    <el-card class="mrgt25">
                        <!--<div class="header-btn-bg">
                      <div>输液宝库存</div>
                    </div>-->
                        <el-row type="flex" align="end">
                            <el-col :span="24">
                                <el-button type="primary" size="small" @click="gainVsersion(deviceData.id, 1)"
                                    :disabled="!deviceData.isOnline">{{
                                        deviceData.isOnline ? '同步设备库存' : '只能同步在线的设备'
                                    }}</el-button>
                                <el-button type="danger" size="small" @click="restartDevice(deviceData.id)">重启设备</el-button>
                                <el-button type="warning" size="small" @click="cutServer(deviceData.id)">切换到{{
                                    env
                                }}</el-button>
                                <el-button type="danger" size="small" @click="popupClick(deviceData)">全部弹出</el-button>
                            </el-col>
                        </el-row>
                        <template>
                            <el-table :data="deviceItems" style="width: 100%">
                                <el-table-column prop="slotNo" label="卡槽"></el-table-column>
                                <el-table-column prop="id" label="ID" width="150px"></el-table-column>
                                <el-table-column label="电量">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.electricity != '--'">{{ scope.row.electricity }}%</span>
                                        <span v-else>{{ scope.row.electricity }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="statusDesc" label="状态"></el-table-column>
                                <el-table-column prop="lastReturnTime" label="归还时间"></el-table-column>
                                <el-table-column label="弹出" width="150px">
                                    <template slot-scope="scope">
                                        <el-button type="danger" size="mini"
                                            @click="takeSlotNo(scope.row.slotNo)">弹出</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </el-card>
        
                </el-card>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import enumConfigs from "@/mixins/enumConfigs"
import * as funDevice from "@/api/device/index"
export default {
    name: "pageDeviceDetails",
    mixins: [enumConfigs],
    data() {
        return {
            id: null,
            env: "",
            deviceData: {},
            merchantRoles: [],
            activeName: "base",
            deviceItems: []
        }
    },
    created() {
        this.initPage();
    },
    activated() {
        if (this.id != this.$route.query.id) {
            this.merchantRoles = []
            this.initPage()
        }
    },
    methods: {
        async initPage() {
            this.id = this.$route.query.id
            window.$common.fullLoading()
            await funDevice.GetDeviceDetail({ id: this.id }).then(res => {
                window.$common.closeFullLoading()
                this.deviceData = res
                this.env = this.deviceData.deviceServerIsProd ? "测试服" : "正式服"
                // 组合设备
                this.deviceItems = []
                if (res.type &&  res.type.slotCount > 0) {
                    for (let i = 1; i <= res.type.slotCount; i++) {
                        let temp = {
                            electricity: "--",
                            id: "--",
                            slotNo: i,
                            status: null,
                            statusDesc: "--",
                            lastReturnTime: "--"
                        }
                        if (res.deviceInfo && res.deviceInfo.items) {
                            let info = res.deviceInfo.items.find(x => {
                                return x.slotNo === temp.slotNo
                            })
                            temp = info || temp
                        }
                        this.deviceItems.push(temp)
                    }
                }
            })
        },
        //   获取版本号
        gainVsersion(id, type) {
            let url = ''
            let params = { id }
            window.$common.fullLoading()
            let _complate = () => {
                window.$common.closeFullLoading()
                this.successMsg("获取成功")
                this.initPage()
            }
            if (type == 1) {  //库存
                funDevice.SyncStockInfo(params).then(res => {
                    _complate()
                })
            } else if (type == 2) {  //网络
                funDevice.SyncNetwork(params).then(res => {
                    _complate()
                })
            } else if (type == 3) { //iccid
                funDevice.SyncICCID(params).then(res => {
                    _complate()
                })
            } else if (type == 4) { //版本
                funDevice.SyncVersion(params).then(res => {
                    _complate()
                })
            } else if (type == 5) { // 同步服务器地址
                funDevice.SyncServerAddress(params).then(res => {
                    _complate()
                })
            }
        },
        takeSlotNo(slotNo) {
            this.confirm(`确认弹出${slotNo}卡位吗`).then(() => {
                window.$common.fullLoading()
                funDevice.Eject({
                    slot: slotNo,
                    id: this.id
                }).then(res => {
                    window.$common.closeFullLoading()
                    this.successMsg("弹出成功");
                    this.initPage();
                })
            })
        },
        restartDevice(id) {
             this.confirm(`确认要重启设备${id}吗？`).then(() => {
                this._restart(id);
            })
        },
        _restart(id) {
            window.$common.fullLoading()
            funDevice.Restart({ id }).then(res => {
                this.successMsg("重启命令发送成功!");
                setTimeout(() => {
                    this.initPage();
                }, 1000);
            })
        },
        //   切换服务器
        cutServer(id) {
            let _complate = () => {
                this.confirm("已成功切换服务器地址，请重启设备后生效", "提示", {
                    confirmButtonText: "重启设备"
                }).then(() => {
                    this._restart(id);
                })
            }
            this.confirm(`确认要切换到${this.env}吗？`).then(() => {
                if (this.env === "正式服") {
                    funDevice.SwitchToProd({ id }).then(res => {
                        this.initPage()
                        _complate()
                    })
                } else {
                    funDevice.SwitchToUAT({ id }).then(res => {
                        this.initPage()
                        _complate()
                    })
                }
            })
        },
        popupClick(row) {
            /* if (!row || !row.deviceInfo || !row.deviceInfo.items || row.deviceInfo.items.length <= 0) {
                this.errorMsg("设备不存在或已全部借出!");
                return false
            } */
            this.confirm(`确认要将设备全部弹出吗？`).then(async () => {
                var sucIndex = 0;
                var len = this.deviceItems.length;
                for (var i = 1; i <= len; i++) {
                    await funDevice.Eject({
                        slot: i,
                        id: row.id
                    }).then(() => {
                        sucIndex = Number(i);
                    })
                }
                
                if (sucIndex >= len) {
                    this.$notify.success("已全部弹出");
                    setTimeout(() => {
                        this.gainVsersion(row.id, 1);
                    }, 2000);
                }
            })
        },
        getDeviceStatusDesc(val) {
            let item = this.getEnumConfigsItemByValue(this.arrDeviceStatus, val)
            let name = item && item.name || ""
            return name
        }
    }
}
</script>

<style lang="scss" scoped>
.page-device-details {
    .el-row {
        margin-bottom: 20px;
    }
}
</style>